// style
import './style/style.css'
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// link
import {NavLink} from "react-router-dom";

const Home = () => {
    document.body.style.backgroundImage = '';
    return (
        <>
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{clickable: true}}
                modules={[Pagination]}
            >
                <SwiperSlide>
                    <article className={'banner banner-first'}>
                        <section className={'container h-100'}>
                            <div className={'row h-100 align-items-end justify-content-center pb-5'}>
                                <div className={'col-12'}>
                                    <div className={'card-slider text-center fw-bold text-white '}>
                                        <h1>کافه گل رزلند</h1>
                                        <h3>تجربه بهترین ها با ما</h3>
                                        <NavLink to={'/menu'} className={'btn btn-light'}>
                                            مشاهده منو
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                </SwiperSlide>
                <SwiperSlide>
                    <article className={'banner banner-second'}>
                        <section className={'container h-100'}>
                            <div className={'row h-100 align-items-end justify-content-center pb-5'}>
                                <div className={'col-12'}>
                                    <div className={'card-slider text-center fw-bold text-white '}>
                                        <h1>کافه گل رزلند</h1>
                                        <h3>فضای داخلی زیبا</h3>
                                        <NavLink to={'/menu'} className={'btn btn-light'}>
                                            مشاهده منو
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                </SwiperSlide>
                <SwiperSlide>
                    <article className={'banner banner-third'}>
                        <section className={'container h-100'}>
                            <div className={'row h-100 align-items-end justify-content-center pb-5'}>
                                <div className={'col-12'}>
                                    <div className={'card-slider text-center fw-bold text-white '}>
                                        <h1>کافه گل رزلند</h1>
                                        <h3>بهترین متریال ها</h3>
                                        <NavLink to={'/menu'} className={'btn btn-light'}>
                                            مشاهده منو
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                </SwiperSlide>
            </Swiper>
        </>
    )
}


export default Home;