// seo
import Seo from "./features/seo/seo";
// router
import Router from "./layouts/router/router";

const App = () => {
    return (
        <>
            {/* seo */}
            <Seo
                title="کافه گل رزلند | قصه دلنشین یک فنجان قهوه"
                description="به دنبال مکانی آرام و دلنشین برای فرار از شلوغی روزمره هستید؟ کافه رزلند با فضایی صمیمی و دکوراسیونی زیبا، پذیرای شماست. از طعم بی‌نظیر قهوه‌هایمان لذت ببرید و ساعاتی خوش را در کنار دوستان خود سپری کنید."
                keywords="کافه،قهوه،منو،قیمت،رزلند"
                type="webapp"
                name="ViraCompany"
            />
            {/* content */}
            <Router/>
        </>
    );
}

export default App;
