// style
import './style/menu.css'
// components
import Header from "../../layouts/header/header";
import Items from "../../components/itemsCard/items";
import Loading from "../../components/loading/loading";
// react query
import {useQuery} from "@tanstack/react-query";
import {getCategory} from "../../services/category/category";
import {getItems} from "../../services/items/items";
// hook
import {useEffect, useState} from "react";
// seo
import Seo from "../../features/seo/seo";

const Menu = () => {
    document.body.style.backgroundImage = '';
    const {data: categories, isLoading: categoryLoad}: any = useQuery<any>({
        queryKey: ['categories'],
        queryFn: getCategory
    });
    const {data: menuItems, isLoading}: any = useQuery({
        queryKey: ['items'],
        queryFn: getItems
    });
    const [items, setItems] = useState<any>()
    const [searchTerm, setSearchTerm] = useState('');
    const [id, setId] = useState<number>();
    useEffect(() => {
        setItems(menuItems);
    }, [menuItems]);
    const changeCategory = (id: number) => {
        let newArray: any = menuItems;
        newArray = newArray.filter((item: object | any) => item.category_id === id && item.status === 1);
        setItems(newArray);
        setId(id)
    }
    const searchItem = () => {
        let newArray: any = menuItems;
        const results: any = newArray.filter((item: any) =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setItems(results);
    }
    if (isLoading || categoryLoad) {
        return (
            <>
                <Seo
                    title="کافه گل رزلند | منو"
                    description="به دنبال مکانی آرام و دلنشین برای فرار از شلوغی روزمره هستید؟ کافه رزلند با فضایی صمیمی و دکوراسیونی زیبا، پذیرای شماست. از طعم بی‌نظیر قهوه‌هایمان لذت ببرید و ساعاتی خوش را در کنار دوستان خود سپری کنید."
                    keywords="کافه،رزلند،قیمت،دسته بندی"
                    type="webapp"
                    name="ViraCompany"
                />
                <Loading/>
            </>
        )
    } else {
        return (
            <>
                {/* seo */}
                <Seo
                    title="کافه گل رزلند | منو"
                    description="به دنبال مکانی آرام و دلنشین برای فرار از شلوغی روزمره هستید؟ کافه رزلند با فضایی صمیمی و دکوراسیونی زیبا، پذیرای شماست. از طعم بی‌نظیر قهوه‌هایمان لذت ببرید و ساعاتی خوش را در کنار دوستان خود سپری کنید."
                    keywords="کافه،رزلند،قیمت،دسته بندی،گل"
                    type="webapp"
                    name="ViraCompany"
                />
                <Header keyKebord={searchItem} search={setSearchTerm}/>
                <article className={'container'}>
                    <section className={'firstCard container mt-2'}>
                        <div className={'row align-items-center h-100 gradiant'}>
                            <div className={'col-7'}>
                                <h6 className={'text-white h6 fw-bold'}>
                                    به کافه گل رزلند خوش آمدید
                                </h6>
                                <p className={'text-white d-lg-block d-none mt-3'}>
                                    به دنبال مکانی آرام و دلنشین برای فرار از شلوغی روزمره هستید؟ کافه رزلند با فضایی
                                    صمیمی و دکوراسیونی زیبا، پذیرای شماست. از طعم بی‌نظیر قهوه‌هایمان لذت ببرید و ساعاتی
                                    خوش را در کنار دوستان خود سپری کنید.
                                </p>
                                <p className={'text-white d-lg-none d-block mt-3'}>
                                    در کافه رزلند، هر فنجان قهوه، یک داستان است
                                </p>
                            </div>
                            <div className={'col-5'}>
                                <img alt={'coffee'} src={require('../../assets/media/about-des.webp')}
                                     className={'img-fluid'}/>
                            </div>
                        </div>
                    </section>
                    <section className={'category mt-5'}>
                        <h5 className={'text-white h4 fw-bold blockquote-footer'}>دسته بندی</h5>
                        <div className={'category-items'}>
                            {categories?.map((category: object | any) => (
                                <div
                                    key={category.id}
                                    onClick={() => changeCategory(category.id)}
                                    className={category.id === id ? 'collision mx-2 text-center active shadow font-medium' : 'collision mx-2 text-center font-medium shadow'}>
                                    <img alt={category.title} className={'img-fluid category-img'} src={category.icon}/>
                                    <p className={'mb-0 font-medium-lg mt-1'}>
                                        {category.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </section>
                    <section className={'items mt-5 mb-5'}>
                        <h5 className={'text-white h4 fw-bold blockquote-footer'}>آیتم ها</h5>
                        <div className={'row mt-4'}>
                            {items?.map((item: object | any, index: number) => (
                                <>
                                    <div className={'col-lg-3 col-md-4 col-6 mb-4'}>
                                        <Items item={item}/>
                                    </div>
                                </>
                            ))}
                        </div>
                    </section>
                </article>
            </>
        )
    }
}

export default Menu;