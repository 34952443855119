export const splitNumber = (number: any) => {
    let counter: any = number;
    let value = counter;
    value = String(value);
    value = value.replace(',', '');
    let x = value.split('.');
    let y = x[0];
    let z = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(y))
        // eslint-disable-next-line no-useless-concat
        y = y.replace(rgx, '$1' + ',' + '$2');
    // show result
    return counter = y + z;
}