const Loading = () => {
    return (
        <>
            <article className={'container align-items-center h-100'}>
                <div className={'row h-100 align-items-center justify-content-center h-100'}>
                    <div className={'col-lg-4 col-6'}>
                        <img alt={'loading'} src={require('./../../assets/media/loading.jpg')} className={'img-fluid loading'}/>
                    </div>
                </div>
            </article>
        </>
)
}

export default Loading;