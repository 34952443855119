// routing
import {Route, Routes} from "react-router-dom";
// links
import {routes} from "../../features/routes/route";

const Router = () => {
    return (
        <>
            <Routes>
                {routes.client.map((link: any) => (
                    <Route key={link.address} element={link.component} path={link.address}/>
                ))}
            </Routes>
        </>
    )
}

export default Router;