// axios
import axios from 'axios';
// routes
import {routes} from "../../features/routes/route";
// key
import {ApiKey} from "../../config/api/apiKey";

export const getDetail = async (data: any) => {
    const {data: response} = await axios.get(`${routes.api[0].address}/items/${data}`, ApiKey);
    return response.data;
};
