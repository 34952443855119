// icons
import {IconSearch} from "@tabler/icons-react";

const Header = (props: any) => {
    return (
        <>
            <header className={'container py-4'}>
                <section className={'shadow navbar'}>
                    <div className={'container'}>
                        <div className={'d-flex w-100 align-items-center justify-content-between'}>
                            <div className={'texts'}>
                                <h1 className={'text-white h3 mb-0 fw-bold'}>کافه گل رزلند</h1>
                            </div>
                            <div className={'icons d-flex align-items-center'}>
                                <IconSearch
                                    data-bs-toggle={'collapse'}
                                    data-bs-target={'#input'}
                                    className={'me-2'}
                                    color={'#FFFFFF'}/>
                                <div className={'collapse collapse-horizontal'} id={'input'}>
                                    <input placeholder={'دنبال چی هستی؟'}
                                           onKeyUp={() => props.keyKebord()}
                                           onChange={(e) => props.search(e.target.value)}
                                           className={'form-control bg-transparent text-white border-0 mx-1'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        </>
    )
}

export default Header;