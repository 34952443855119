// components
import Menu from "../../pages/menu/menu";
import Home from "../../pages/home/home";
import Detail from "../../pages/detail/detail";

export const routes: object | any = {
    client: [
        {
            label: 'خانه',
            address: '/',
            component: <Home/>,
        },
        {
            label: 'منو',
            address: '/menu',
            component: <Menu/>,
        },
        {
            label: 'دیتیل منو',
            address: '/menu/:id',
            component: <Detail/>,
        },
    ],
    api: [
        {
            name: 'api',
            address: 'https://dashboard.caferoseland.ir/api',
            group: 'api'
        },
    ]
}
