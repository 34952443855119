// style
import './style/detail.css'
// use query
import {useQuery} from "@tanstack/react-query";
import {getDetail} from "../../services/items/itemDetail";
// router
import {useParams} from "react-router-dom";
// loading
import Loading from "../../components/loading/loading";
// number
import {splitNumber} from "../../features/splitNumber/splitNumber";
// icon
import {IconShare, IconStar} from "@tabler/icons-react";
// seo
import Seo from "../../features/seo/seo";

const Detail = () => {
    const param = useParams();
    const {data, isLoading}: any = useQuery<any>({
        queryKey: ['detail', param.id],
        queryFn: () => getDetail(param.id),
    });
    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: document.title,
                    text: data.title,
                    url: window.location.href,
                });
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            console.error('Error sharing content:');
        }
    };
    if (param.id) {
        document.body.style.backgroundImage = `url(${data?.image})`
    }
    if (isLoading) {
        return (
            <Loading/>
        )
    } else {
        return (
            <>
                <Seo
                    title={`کافه گل رزلند | ${data.title}`}
                    description="به دنبال مکانی آرام و دلنشین برای فرار از شلوغی روزمره هستید؟ کافه رزلند با فضایی صمیمی و دکوراسیونی زیبا، پذیرای شماست. از طعم بی‌نظیر قهوه‌هایمان لذت ببرید و ساعاتی خوش را در کنار دوستان خود سپری کنید."
                    keywords="توضیحات،قیمت،تومان،اشتراک،رزلند"
                    type="webapp"
                    name="ViraCompany"
                />
                <article className={'h-100 gradiant'}>
                    <div className={'d-flex align-items-end h-100'}>
                        <div className={'blur-card card card-body border-0 text-white w-100'}>
                            <div className={'d-flex justify-content-between'}>
                                <span className={'fw-bold'}>
                                    {data?.title}
                                </span>
                                <span className={'fw-bold'}>
                                    {splitNumber(data?.price)}
                                    تومان
                                </span>
                            </div>
                            <hr/>
                            <h6 className={'text-white fw-bold'}>توضیحات:</h6>
                            <p className={'text-white fw-bold'}>
                                {data?.description}
                            </p>
                            <div className={'d-flex justify-content-center'}>
                                <button className={'btn btn-light btn-transparent'}>
                                    <IconStar/>
                                </button>
                                <button className={'btn btn-light mx-3 w-100 btn-transparent'}>
                                    موجود
                                </button>
                                <button onClick={() => handleShare()} className={'btn btn-light btn-transparent'}>
                                    <IconShare/>
                                </button>
                            </div>
                        </div>
                    </div>
                </article>
            </>
        )
    }
}

export default Detail;