// icons
import {IconShare, IconStar} from "@tabler/icons-react";
// number
import {splitNumber} from "../../features/splitNumber/splitNumber";
// link
import {NavLink} from "react-router-dom";

const Items = (props: any) => {
    return (
        <>
            <NavLink to={`/menu/${props.item.id}`}>
                <div
                    data-aos={'fade-in'}
                    data-aos-duration={'2000'}
                    className={'card card-body item-card rounded shadow rounded-4 border-0'}>
                    <img
                        alt={props.item.title}
                        className={'card-img rounded rounded-4'}
                        src={props.item.image ? props.item.image : require('./../../assets/media/image.jfif')}
                    />
                    <p className={'card-title text-split text-white mt-2 mb-1'}>
                        {props.item.title}
                    </p>
                    <div className={'card-description d-flex justify-content-between align-items-center w-100'}>
                        <p className={'card-text text-split fw-light font-medium-lg mb-0'}>
                            {props.item.description}
                        </p>
                        <IconStar size={'13'} className={'text-warning'}/>
                    </div>
                    <div className={'d-flex mt-2 justify-content-between align-items-center'}>
                        <p className={'card-title text-white font-medium-lg fw-bold mb-0'}>
                            {splitNumber(props.item.price)}
                            <span className={'ms-1'}>
                        تومان
                        </span>
                        </p>
                        <div className={'circle-div'}>
                            <IconShare size={'14'} color={'#FFFFFF'}/>
                        </div>
                    </div>
                </div>
            </NavLink>
        </>
    )
}

export default Items;